import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import SeasonYear from '../../components/SeasonYear';
import DeerValleyImage from '../../assets/images/publication-banners/deer-valley-music-festival.jpg';
import { graphql } from 'gatsby';

export const query = graphql`
  query DvmfQuery {
    allDvmfCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function Dvmf({ data }) {
  const dvmfNodes = data.allDvmfCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/dvmf/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Deer Valley® Music Festival - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Deer Valley® Music Festival’s  playbill. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Deer Valley® Music Festival</h1>
            </header>
            <span className="image main">
              <img src={DeerValleyImage} alt="" />
            </span>
            <p className="box">
              The Deer Valley® Music Festival is celebrating its 20th
              anniversary of providing classical, opera, chamber, and pop music
              at the Deer Valley® Snow Park Outdoor Amphitheater and St. Mary’s
              Church. The festival has consistently delivered a diverse,
              high-quality musical experience in a casual setting of
              unparalleled natural beauty. Advertisers will be exposed to an
              audience of affluent patrons with refined tastes who not only
              value the finest musical performances, but also the finest quality
              of products and services.
              <br />
              <br />
              Advertisers will be exposed to an audience of affluent patrons
              with refined tastes who not only value the finest musical
              performances, but also the finest quality of products and
              services.
              <br />
              <br />
              Festival attendees will have access to print and digital programs
              with information at the concerts and events this season. Programs
              will be available in the VIP section and at the entrance of the
              Festival with easy access to a mobile-friendly program through QR
              Codes posted throughout the Festival. Anticipated total readership
              of 30,000.
            </p>

            <h3>
              <i className="icon fa-users"></i> Demographics
            </h3>
            <div className="grid-wrapper demographics">
              <section className="col-6 box">
                <i className="icon fa-home demo-icon"></i>
                <p>
                  43% have an Annual Household{' '}
                  <strong>Income of over $100,000.</strong>
                </p>
              </section>
              <section className="col-6 box">
                <i className="icon fa-dollar demo-icon"></i>
                <p>
                  Average total spending <strong>per attendee was $367.</strong>
                </p>
              </section>
            </div>
            <ul className="actions">
              <li>
                <a
                  href="https://deervalleymusicfestival.org/"
                  className="button special"
                >
                  <i className="icon fa-globe"> </i>Deer Valley® Music Festival
                  Website
                </a>
              </li>
            </ul>
            <h2>
              Deer Valley® Music Festival <SeasonYear /> Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {dvmfNodes.map((node) => (
                <div className="col-3 box" key={node.id}>
                  {node.Image && (
                    <img
                      style={{ width: 100 + '%' }}
                      src={imageRef + node.Image}
                    />
                  )}
                  <h3>{node.SHOW}</h3>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  <p>{node.Venue} </p>
                  {node.Quantity && <p> Circulation: {node.Quantity}</p>}
                  {node.FR && <p> File Ready Date: {node.FR}</p>}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
